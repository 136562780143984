import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseInput from 'shared_DEPRECATED/components/Form/Input/BaseInput';

const TextInputPropTypes = {
  /**
   * @type {string}
   * @memberof TextInputPropTypes
   * @name defaultValue
   * @example <BaseInput defaultValue="John Doe" />
   */
  defaultValue: PropTypes.string,
  /**
   * @type {string}
   * @memberof TextInputPropTypes
   * @name name
   * @example <BaseInput name="John Doe" />
   */
  name: PropTypes.string,
  /**
   * @type {string}
   * @memberof TextInputPropTypes
   * @name placeholder
   * @example <BaseInput placeholder="John Doe" />
   */
  placeholder: PropTypes.string,
  /**
   * @type {('sm'|'lg')}
   * @required
   * @memberof TextInputPropTypes
   * @name size
   * @example <BaseInput size="sm" />
   */
  size: PropTypes.oneOf(['sm', 'lg']),
  /**
   * @type {boolean}
   * @required
   * @memberof TextInputPropTypes
   * @name error
   * @example <BaseInput error />
   */
  error: PropTypes.bool,
  dataTestid: PropTypes.string,
};

const TextInput = forwardRef(
  (
    { defaultValue = '', name, placeholder = '', dataTestid = null, ...props },
    ref
  ) => (
    <BaseInput
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      ref={ref}
      type="text"
      data-testid={dataTestid}
      {...props}
    />
  )
);

TextInput.propTypes = TextInputPropTypes;

export default TextInput;
