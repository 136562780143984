import { ColorModeScript } from '@chakra-ui/react';
import { ClickToComponent } from 'click-to-react-component';
import ReactDOM from 'react-dom';

import App from 'app/App';
import 'lib/amplitude/init';
import 'lib/googleTagManager/init';
import 'lib/hotjar/init';
import 'shared_DEPRECATED/config/date';

import { theme } from 'shared/theme';

ReactDOM.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ClickToComponent />
    <App />
  </>,
  document.getElementById('root')
);
