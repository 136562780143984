import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { useSidebarContext } from 'shared';
import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

import { DAILY_STATUS, TARGET_PERIODS } from 'features/challenge/config';
import { ChallengeTargetProgressType } from 'features/challenge/config/types';
import { useGetChallengeFrequencyText } from 'features/challenge/hooks/useGetFrequencyText';
import { useMomentumFeedContext } from 'features/momentum/context/feed';
import { Sprint } from 'features/sprint';

const getNonZeroMomentumItems = (
  {
    momentumDiff,
    challenge,
    feedDate,
  }: {
    momentumDiff: {
      currentValue: number;
      changeValue: number;
    };
    challenge: {
      target: ChallengeTargetProgressType;
      title: string;
    };
    feedDate: string;
  },
  getChallengeFrequencyText: (
    target: ChallengeTargetProgressType,
    sprintDuration?: number
  ) => string,
  sprintWeeksCount?: number
) => ({
  currentValue: momentumDiff.currentValue,
  changeValue: momentumDiff.changeValue,
  challengeFrequency: getChallengeFrequencyText(
    challenge.target,
    sprintWeeksCount
  ),
  challengeTargetAmount:
    'amount' in challenge.target && challenge.target.amount
      ? `${challenge.target.amount.value} ${challenge.target.amount.unit} per ${
          TARGET_PERIODS[
            challenge.target.period!.toUpperCase() as keyof typeof TARGET_PERIODS
          ]
        }`
      : '',
  challengeTitle: challenge.title,
  challengeStatus: ('status' in challenge.target
    ? challenge.target.status || DAILY_STATUS_VALUES.EMPTY
    : challenge.target.dailyStatus[feedDate]?.status ||
      DAILY_STATUS_VALUES.EMPTY) as DAILY_STATUS,
});

const getZeroMomentumChallenge = (
  {
    feedDate,
    challenge: { target, title },
  }: {
    feedDate: string;
    challenge: {
      target: ChallengeTargetProgressType;
      title: string;
    };
  },
  getChallengeFrequencyText: (
    target: ChallengeTargetProgressType,
    sprintDuration?: number
  ) => string,
  sprintWeeksCount?: number
) => ({
  frequency: getChallengeFrequencyText(target, sprintWeeksCount),
  status: ('status' in target
    ? target.status || DAILY_STATUS_VALUES.EMPTY
    : target.dailyStatus[feedDate]?.status ||
      DAILY_STATUS_VALUES.EMPTY) as DAILY_STATUS,
  targetAmount:
    'amount' in target && target.amount
      ? `${target.amount.value} ${target.amount.unit} per ${
          TARGET_PERIODS[
            target.period!.toUpperCase() as keyof typeof TARGET_PERIODS
          ]
        }`
      : '',
  title: title,
});

const selectMomentumFeed = (
  data: {
    challenges: {
      momentumDifference: Record<
        string,
        {
          currentValue: number;
          changeValue: number;
        }
      >;
      target: ChallengeTargetProgressType;
      title: string;
    }[];
  },
  getChallengeFrequencyText: (
    target: ChallengeTargetProgressType,
    sprintDuration?: number
  ) => string,
  sprintWeeksCount?: number
) =>
  data.challenges.reduce(
    (feed, challenge) => {
      for (const [date, momentumDiff] of Object.entries(
        challenge.momentumDifference
      )) {
        if (!feed[date]) {
          feed[date] = {
            momentumScoreDiff: 0,
            items: [],
            zeroMomentumItems: {
              accumulatedChangeValue: 0,
              challenges: [],
            },
          };
        }

        feed[date].momentumScoreDiff += momentumDiff.changeValue;

        if (momentumDiff.currentValue > 0) {
          const feedItem = getNonZeroMomentumItems(
            {
              momentumDiff,
              challenge,
              feedDate: date,
            },
            getChallengeFrequencyText,
            sprintWeeksCount
          );

          feed[date].items.push(feedItem);
        } else {
          const zeroMomentumChallenge = getZeroMomentumChallenge(
            {
              challenge,
              feedDate: date,
            },
            getChallengeFrequencyText,
            sprintWeeksCount
          );

          feed[date].zeroMomentumItems = {
            accumulatedChangeValue:
              feed[date].zeroMomentumItems.accumulatedChangeValue +
              momentumDiff.changeValue,
            challenges: [
              ...feed[date].zeroMomentumItems.challenges,
              zeroMomentumChallenge,
            ],
          };
        }
      }

      return feed;
    },
    {} as Record<
      string,
      {
        momentumScoreDiff: number;
        items: {
          currentValue: number;
          changeValue: number;
          challengeFrequency: string;
          challengeTargetAmount: string;
          challengeTitle: string;
          challengeStatus: DAILY_STATUS;
        }[];
        zeroMomentumItems: {
          accumulatedChangeValue: number;
          challenges: {
            frequency: string;
            status: DAILY_STATUS;
            targetAmount: string;
            title: string;
          }[];
        };
      }
    >
  );

export const useMomentumFeedQuery = () => {
  const { sprints } = useSidebarContext() as { sprints: Sprint[] };
  const { sprintId } = useMomentumFeedContext();
  const { request } = useQueryHTTPRequest();
  const getChallengeFrequencyText = useGetChallengeFrequencyText();
  const sprint = sprints.find((sprint) => sprint.sprintId === sprintId);

  return useQuery(
    ['momentum', sprintId],
    async () =>
      await request({ url: `/api/sprints/${sprintId}/momentum/feed` }),
    {
      select: useCallback(
        (data) =>
          selectMomentumFeed(
            data,
            getChallengeFrequencyText,
            sprint?.weeksCount
          ),
        [getChallengeFrequencyText, sprint]
      ),
    }
  );
};
